<script>
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
export default {
  mixins: [mixinVerticalNavMenuLink],
  computed: {
    linkActive () {
      return this.isActive.value
    }
  }
}
</script >

<script setup>
import { useUtils as useAclUtils } from '@core/libs/acl'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import { defineProps } from 'vue'
import { BLink, BBadge } from 'bootstrap-vue'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})

const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
const { canViewVerticalNavMenuLink } = useAclUtils()
</script>
<template>
  <li v-if="canViewVerticalNavMenuLink(item)" class="nav-item" :class="{ active: linkActive, disabled: item.disabled }">
    <b-link :to="linkProps.value.to" class="d-flex align-items-center">
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ item.title }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>