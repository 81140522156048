<template>
  <b-nav-item @click="skin = isDark ? 'light' : 'dark'">
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
</template>
<script setup>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from 'vue'
import { BNavItem } from 'bootstrap-vue'
const { skin } = useAppConfig()

const isDark = computed(() => skin.value === 'dark')
</script>
