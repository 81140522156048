<template>
  <li v-if="canViewVerticalNavMenuGroup(item)" class="nav-item has-sub" :class="{
    'open': isOpen,
    'disabled': item.disabled,
    'sidebar-group-active': isActive,
  }">
    <b-link class="d-flex align-items-center" @click="() => updateGroupOpen(!isOpen)">
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ item.title }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse v-model="isOpen" class="menu-content" tag="ul">
      <component :is="resolveNavItemComponent(child)" v-for="child in item.children" :key="child.header || child.title"
        ref="groupChild" :item="child" />
    </b-collapse>
  </li>
</template>

<script>
// Composition Function
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'

export default {
  inject: ['isMouseHovered', 'openGroups'],
  mixins: [mixinVerticalNavMenuGroup],
  watch: {
    isMouseHovered() {
      if (this.isVerticalMenuCollapsed.value) {
        this.isOpen = val && this.isActive
      }
    },
    isOpen(val) {
      if (val) this.openGroups.push(props.item.title)
    },
    isVerticalMenuCollapsed(val) {
      if (!isMouseHovered) {
        if (val) this.isOpen = false
        else if (!val && this.isActive) this.isOpen = true
      }
    },
    isActive(val) {
      if (val) {
        if (!this.isVerticalMenuCollapsed) this.isOpen = val
      } else {
        this.isOpen = val
      }
    }
  }
}
</script>
<script setup>
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import { BLink, BBadge, BCollapse } from 'bootstrap-vue'
import { defineProps, computed, watch, inject, ref } from 'vue'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { isNavGroupActive } from '@core/layouts/utils'
import store from '@/store'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})

const isVerticalMenuCollapsed = computed(() => store.state.verticalMenu.isVerticalMenuCollapsed)
const isOpen = ref(false)
const isActive = ref(false)

const updateGroupOpen = val => {
  isOpen.value = val
}


const updateIsActive = () => {
  isActive.value = isNavGroupActive(props.item.children)
}


const { canViewVerticalNavMenuGroup } = useAclUtils()

const resolveNavItemComponent = function (item) {
  if (item.header) return VerticalNavMenuHeader
  return VerticalNavMenuLink
}
</script>
