<template>
  <ul>
    <component :is="resolveNavItemComponent(item)" v-for="item in items" :key="item.header || item.title" :item="item" />
  </ul>
</template>

<script>
import { ref } from 'vue'
export default {
  provide () {
    return {
      openGroups: ref([])
    }
  }
}
</script>

<script setup>
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import { defineProps } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
})

const resolveNavItemComponent = function (item) {
  if (item.header) return VerticalNavMenuHeader
  if (item.children) return VerticalNavMenuGroup
  return VerticalNavMenuLink
}

// provide('openGroups', ref([]))
</script>
