import { render, staticRenderFns } from "./LayoutVertical.vue?vue&type=template&id=5da79822&"
import script from "./LayoutVertical.vue?vue&type=script&setup=true&lang=js&"
export * from "./LayoutVertical.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./LayoutVertical.vue?vue&type=style&index=0&id=5da79822&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports